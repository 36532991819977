<template>
  <div class="grid-cols-6">
    <div class="card bg-white p-4 rounded-md">
      <Steps :model="items" :readonly="true"/>
    </div>
    <br>
    <router-view v-slot="{Component}" >
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import Steps from 'primevue/steps'
import { ref } from 'vue'
export default {
  name: 'index',
  components: { Steps },
  setup () {
    const items = ref([
      {
        label: 'Gestión envío',
        to: '/compras/logistica/control-envios/informacion-principal'
      },
      {
        label: 'Información logística',
        to: '/compras/logistica/control-envios/informacion-logistica'
      }
    ])

    return {
      items
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}
</style>
